import React, { Fragment } from 'react'
import Hero from '../components/UI/Hero'
import VehicleListing from '../components/UI/VehicleListing'
import Container from '../components/UI/Container'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import SEO from '../components/SEO'

const BioWrapper = styled.div`
  position: relative;
  padding: 2rem 0;
  section {
    position: relative;
    justify-content: flex-end;
    z-index: 1;
    .content {
      width: 60%;
      @media (max-width: ${props => props.theme.sizes.mobile}) {
        width: 100%;
      }
    }
    .title {
      padding-bottom: 1.5rem;
      h2 {
        margin: 0;
        font-size: 30px;
        line-height: 30px;
        padding-left: 10px;
        padding-bottom: 0;
        padding-top: 7px;
      }
    }
  }
`
const BioImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 600px;
  height: 100% !important;
  z-index: 0;

  @media (max-width: ${props => props.theme.sizes.mobile}) {
    display: none;
  }
`

const IndexPage = props => (
  <Fragment>
    <SEO
      title={`Home`}
      description="A collection of timeless, custom, and handpicked vehicles."
    />
    <Hero
      heroStyle="index"
      title="SGL Motors"
      description={props.data.homePageData.edges[0].node.data.homeDescription}
      background={
        props.data.homePageData.edges[0].node.data.homeHeroImage.localFiles[0]
          .childImageSharp.fluid
      }
    />
    <VehicleListing
      title={'Featured Vehicles'}
      icon={props.data.wheel.childImageSharp.fixed}
      vehicles={props.data.featuredVehicles.edges}
    />
    <BioWrapper>
      <BioImg
        fluid={
          props.data.homePageData.edges[0].node.data.homeBioImage.localFiles[0]
            .childImageSharp.fluid
        }
        backgroundColor={'#e3e3e3'}
      />
      <Container>
        <div className="content">
          <div className="title">
            <h2>{props.data.homePageData.edges[0].node.data.homeBioTitle}</h2>
          </div>
          <div
            className="copy"
            dangerouslySetInnerHTML={{
              __html: props.data.homePageData.edges[0].node.data.homeBio,
            }}
          />
        </div>
      </Container>
    </BioWrapper>
  </Fragment>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    featuredVehicles: allAirtable(
      filter: { table: { eq: "Vehicles" }, data: { featured: { eq: true } } }
    ) {
      edges {
        node {
          data {
            vehicleImages {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            vehicleMake
            vehicleModel
            vehicleYear
            vehicleName
            active
          }
        }
      }
    }
    wheel: file(relativePath: { eq: "wheel.png" }) {
      childImageSharp {
        fixed(width: 28, height: 28) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    homePageData: allAirtable(filter: { table: { eq: "Home" } }) {
      edges {
        node {
          data {
            homeHeroImage {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            homeBioImage {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            homeBioIcon {
              localFiles {
                childImageSharp {
                  fixed(width: 48, height: 46) {
                    ...GatsbyImageSharpFixed_tracedSVG
                  }
                }
              }
            }
            homeTitle
            homeDescription
            homeBioTitle
            homeBio
          }
        }
      }
    }
  }
`
